import { Injectable } from '@angular/core';
import { HttpRequestService } from '@core/http/http-request.service';
import { APIEndpoint } from 'app/constants/api-endpoint';
import { Observable } from 'rxjs';
import { LoginCredential } from '../models/login-credential';
import { REFRESHTOKEN_KEY, TOKEN_COMPARISON_KEYS, TOKEN_KEY, USER_KEY } from 'app/constants/constant';
import { MessageService } from '@shared/message/message.service';
import jwt_decode from "jwt-decode";

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor(private _http: HttpRequestService, private messageService: MessageService) {}

  public login(loginPayload: LoginCredential): Observable<any> {
    return this._http.post(APIEndpoint.SIGNIN, loginPayload);
  }

  public logOut() {
    return this._http.put(APIEndpoint.LOGOUT);
  }

  public getUserProfile() {
    return this._http.get(APIEndpoint.GET_LOGIN_USER_INFO);
  }

  public saveAuthUser(user: any) {

    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, JSON.stringify(user.access_token));

    window.sessionStorage.removeItem(REFRESHTOKEN_KEY);
    window.sessionStorage.setItem(REFRESHTOKEN_KEY, JSON.stringify(user.refresh_token));
  }

  getToken(){
    try{
      return JSON.parse(window.sessionStorage.getItem(TOKEN_KEY) as string);
    }
    catch(e){
      this.messageService.error("Please use valid user ID and password for login");
      return null;
    }
  }

  getRefreshToken(){
    try{
      return JSON.parse(window.sessionStorage.getItem(REFRESHTOKEN_KEY) as string);
    }
    catch(e){
      this.messageService.error("Please use valid user ID and password for login");
      return null;
    }
  }

  refreshToken(token: any) {
    const payload = {refresh_token: token}
    return this._http.post(APIEndpoint.REFRESH_TOKEN, payload);
  }

  get isLoggedIn(): boolean {
    const token = this.getToken();
    if (token) {
        return this.isTokenPayloadValid(token) && !this.isTokenExpired(token);
    }
    return false;
  }

  getTokenExpirationDate(token: string): Date {
    let decoded: any
    try {
      decoded = jwt_decode(token);
    }
    catch (e) {
      this.messageService.error("Please use valid user ID and password for login");
    }

    if (decoded.exp === undefined) return null as any;

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  isTokenPayloadValid(token: string): boolean {
    let decoded!: any;
    let tokenKeys!: any;
    const compareKeys = TOKEN_COMPARISON_KEYS;
    try {
      decoded = jwt_decode(token);
      tokenKeys = Object.keys(decoded);
      return (compareKeys.length === tokenKeys.length) && compareKeys.every((element, index) => element === tokenKeys[index]);
    }
    catch (e) {
      this.messageService.error("Please use valid user ID and password for login");
      return false;
    }
  }

  isTokenExpired(token: string): boolean {
    if (!token) return true;

    const date: Date = this.getTokenExpirationDate(token);
    return !(date.valueOf() > new Date().valueOf());
  }

  clearStorage(): void {
    window.sessionStorage.clear();
  }

}
