import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CoreModule } from '@core/core.module';
import { apiBaseUrlInterceptor } from '@core/http/interceptors/api-base-url.interceptor';
import { httpErrorHandlerInterceptor } from '@core/http/interceptors/http-error-handler.interceptor';
import { loaderInterceptor } from '@core/http/interceptors/loader.interceptor';
import { tokenInterceptor } from '@core/http/interceptors/token.interceptor';
import { environment } from '@env';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DatePipe } from '@angular/common';


@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    HttpClientModule,
    MatSnackBarModule,
  ],
  providers: [
    apiBaseUrlInterceptor,
    tokenInterceptor,
    loaderInterceptor,
    httpErrorHandlerInterceptor,
    DatePipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
