import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from '@shared/message/message.service';
import { AuthService } from 'app/auth/services/auth.service';
import { AUTH_HEADER, PUBLIC_API_LIST } from 'app/constants/constant';
import {
  BehaviorSubject,
  Observable,
  catchError,
  filter,
  switchMap,
  take,
  throwError,
} from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private authService: AuthService,
    private router: Router,
    private messageService: MessageService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.authService.getToken();
    const req = this.addAuthenticationToken(request, token);
    return next.handle(req).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          !request.url.includes('login') &&
          error.status === 401
        ) {
          return this.handleInvalidTokenRequest(request, next);
        }

        return throwError(() => error);
      })
    );
  }

  private handleInvalidTokenRequest(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.refreshTokenInProgress) {
      this.refreshTokenInProgress = true;
      this.refreshTokenSubject.next(null);

      const refreshToken = this.authService.getRefreshToken();

      if (refreshToken) {
        return this.authService.refreshToken(refreshToken).pipe(
          switchMap((user: any) => {
            this.refreshTokenInProgress = false;

            this.authService.saveAuthUser(user);
            this.refreshTokenSubject.next(user);

            return next.handle(this.addAuthenticationToken(request, user.token));
          }),
          catchError((err) => {
            this.refreshTokenInProgress = false;
            if(err.status === 401){
              this.messageService.error("Your session has expired, please login.")
            }
            this.authService.clearStorage();
            this.router.navigate(['/login']);
            return throwError(() => err);
          })
        );
      } else {
        this.authService.clearStorage();
        this.router.navigate(['/login']);
      }
    }

    return this.refreshTokenSubject.pipe(
      filter((user) => user !== null),
      take(1),
      switchMap((user) => next.handle(this.addAuthenticationToken(request, user.token)))
    );
  }

  private addAuthenticationToken(request: HttpRequest<any>, token: string | null): HttpRequest<any> {
    // If token is not available then return the request without adding token.
    if (!token) {
      return request;
    }
    // If request url is public then there is no need to add token

    if (this.skipAddToken(PUBLIC_API_LIST, request.url)) {
      return request;
    }

    //If request url is private then we have to add token
    return request.clone({
      headers: request.headers.set(AUTH_HEADER, 'Bearer ' + token),
    });
  }

   //function to check if the requested url is present in the public API list.
   private skipAddToken(skipList: string[], requestUrl: string){
    for (var i = 0; i < skipList.length; i++) {
      if (requestUrl.indexOf(skipList[i]) !== -1) {
        return true;
      }
    }
    return false;
  }
}

export const tokenInterceptor = {
  provide: HTTP_INTERCEPTORS,
  useClass: TokenInterceptor,
  multi: true,
};
