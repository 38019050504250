import { APIEndpoint } from "./api-endpoint";

export const AUTH_HEADER = 'Authorization';
export const TOKEN_KEY = '_9a7176ea-577e-4e9e-88e3-f8f3088c3a93';
export const REFRESHTOKEN_KEY = '_64bdd0b6-bef3-49e5-b478-42a0c4e66fa5';
export const USER_KEY = '_05904600-3edb-418d-a197-65a638bcb988';

export const TOKEN_COMPARISON_KEYS = ["loginId", "role", "clientName", "iat", "exp",];
export const SEARCH_FIELD_EVENTS = ['keyup'];

export const PAGE_SIZE = 10;
export const PAGE_SIZE_OPTIONS = [5, 10, 25, 50, 100];

export const NID_REGEX = "^([0-9]{10}|[0-9]{13}|[0-9]{17})$";
export const EMAIL_REGEX = "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+[.][a-zA-Z0-9-.]+$";
export const MOBILE_NO_REGEX = "^(0|[0+]880)1[3-9][0-9]{8}$";
export const LICENCE_NO_REGEX = "^\[0-9]{4}/\[0-9]{2}$";
export const BILL_GENERATION_ROUTE_REGEX = /^(due|approved|generated)\/(20[0-9][0-9]|2[1-9][0-9]{2}|[3-9][0-9]{3})-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;

export const AVOID_LOADER_LIST = [
    APIEndpoint.LIVE_STATISTIC, 
    APIEndpoint.GET_DUMMY_API,
    APIEndpoint.GET_ALL_NODE_LIST
];

export const PUBLIC_API_LIST = [

];
