export class APIEndpoint {
  static SIGNIN = "/v1/security/signin";
  static LOGOUT = "/v1/security/logout";
  static GET_LOGIN_USER_INFO = "/v1/user-management/get-login-info";
  static REFRESH_TOKEN = "/token/refresh";
  static RESET_PASSWORD = "/v1/user-management/reset-password";

  static GET_NODE_LIST = "/v1/management/node/get-list";
  static GET_ALL_NODE_LIST = "/v1/management/node/get-all-list";
  static GET_SINGLE_NODE = "/v1/management/node/get-details";
  static ADD_SINGLE_NODE = "/v1/management/node/add-node";
  static UPDATE_SINGLE_NODE = "/v1/management/node/update-node";

  static GET_TIME_ZONE_LIST = "/v1/management/timezone/get-timezone-list";

  static GET_PARTNER_LIST = "/v1/management/partner/get-list";
  static GET_PARTNER_DETAILS = "/v1/management/partner/get-details";
  static ADD_PARTNER = "/v1/management/partner/add-partner";
  static UPDATE_PARTNER = "/v1/management/partner/update-partner";
  static UPDATE_PARTNER_STATUS = "/v1/management/partner/update-partner-status";
  static GET_PARTNER_NAME_LIST = "/v1/management/partner/get-name-list";
  static GET_NATPREFIX_LIST = "/v1/management/natprefix/get-list";
  static GET_NATPREFIX_DETAILS = "/v1/management/natprefix/get-details";
  static ADD_NATPREFIX = "/v1/management/natprefix/add-natprefix";
  static UPDATE_NATPREFIX = "/v1/management/natprefix/update-natprefix";

  static GET_TRUNK_LIST = "/v1/management/trunk/get-list";
  static GET_ALL_TRUNK_LIST = "/v1/management/trunk/get-all-list";
  static ADD_TRUNK = "/v1/management/trunk/add-trunk";
  static UPDATE_TRUNK = "/v1/management/trunk/update-trunk";
  static UPDATE_TRUNK_STATUS = "/v1/management/trunk/update-trunk-status";
  static GET_TRUNK_DETAILS = "/v1/management/trunk/get-details";

  static GET_REGION_LIST = "/v1/management/region/get-list";
  static ADD_REGION = "/v1/management/region/add-region";
  static UPDATE_REGION = "/v1/management/region/update-region";
  static GET_REGION_DETAILS = "/v1/management/region/get-details";

  static GET_NATRATESHEET_LIST = "/v1/management/natratesheet/get-list";
  static ADD_NATRATESHEET = "/v1/management/natratesheet/add-natratesheet";
  static UPDATE_NATRATESHEET = "/v1/management/natratesheet/update-natratesheet";
  static UPDATE_NATRATESHEET_STATUS = "/v1/management/natratesheet/update-natratesheet-status";
  static GET_NATRATESHEET_DETAILS = "/v1/management/natratesheet/get-details";
  static GET_NATRATE_TABLE_DETAILS = "/v1/management/natratetable/get-details";
  static ADD_NATRATE_TABLE = "/v1/management/natratetable/add-natratetable";
  static UPDATE_NATRATE_TABLE = "/v1/management/natratetable/update-natratetable";
  static UPDATE_NATRATE_TABLE_STATUS = "/v1/management/natratetable/update-natratetable-status";

  static GET_CURRENCY_LIST = "/v1/management/currency/get-list";
  static ADD_CURRENCY = "/v1/management/currency/add-currency";
  static UPDATE_CURRENCY = "/v1/management/currency/update-currency";
  static GET_CURRENCY_DETAILS = "/v1/management/currency/get-details";

  static ADD_OUTGOINGYRATES = "/v1/management/outgoingyrates/add-outgoingyrates";
  static GET_OUTGOINGYRATES_LIST = "/v1/management/outgoingyrates/get-list";
  static GET_OUTGOINGYRATES_DETAILS = "/v1/management/outgoingyrates/get-details";
  static UPDATE_OUTGOINGYRATES = "/v1/management/outgoingyrates/update-outgoingyrates";

  static GET_USER_LIST = "/v1/user-management/get-list";
  static ADD_USER_PROFILE = "/v1/user-management/add-user";
  static UPDATE_USER_PROFILE = "/v1/user-management/update-user";
  static UPDATE_USER_PROFILE_STATUS = "/v1/user-management/update-user-status";
  static GET_USER_PROFILE_DETAILS = "/v1/user-management/get-details";

  static GET_ROLE_LIST = "/v1/management/role/get-list";

  static GET_CALENDER_EVENTS = "/v1/bill-management/get-due-count";
  static MANAGE_INVOICE = "/v1/bill-management/manage-invoice";
  static GET_INVOICE_LIST = "/v1/bill-management/get-invoice-list";
  static GENERATE_INVOICE = "/v1/bill-management/generate-invoice";
  static UNGENERATE_INVOICE = "/v1/bill-management/ungenerate-invoice";
  static APPROVE_INVOICE = "/v1/bill-management/approve-generated-invoice";
  static DOWNLOAD_INVOICE = "/v1/bill-management/download-invoice";

  static GET_CHART_DATA = "/v1/dashboard/dashboard-info";
  static MANAGEMENT_INFO = "/v1/dashboard/management-info";

  static GET_IGW_PARTNER_LIST = "/v1/management/igw-partner/get-list";
  static GET_ANS_PARTNER_LIST = "/v1/management/ans-partner/get-list";
  static GET_IOS_PARTNER_LIST = "/v1/management/ios-partner/get-list";

   static ADD_OUTGOINGXRATES = "/v1/management/outgoingxrates/add-outgoingxrates";
   static GET_OUTGOINGXRATES_LIST = "/v1/management/outgoingxrates/get-list";
   static GET_OUTGOINGXRATES_DETAILS = "/v1/management/outgoingxrates/get-details";
   static UPDATE_OUTGOINGXRATES = "/v1/management/outgoingxrates/update-outgoingxrates";

   static GET_RERATE_LIST = "/v1/management/rerating/get-rerate-list";
   static  GET_REASON_FOR_RERATE_FAILED_CALL_COUNT = "/v1/management/rerating/get-failed-call-count";
   static  GET_REASON_DETAILS_FOR_RERATE_FAILED_CALL_COUNT = "/v1/management/rerating/get-failed-call-reason-list";
  // Report

  static GET_CALL_VOLUME_BY_ROUTE_LIST = "/v1/report/btrc-reports/get-call-volume-by-route";
  static REPORT_CALL_DETAILS_LIST = "/v1/report/call-reports/call-details-report";
  static GET_CALL_VOLUME_BY_IGW_LIST = "/v1/report/btrc-reports/get-call-volume-by-igw";
  static GET_BTRC_CALL_VOLUME_BY_ANS_LIST = "/v1/report/btrc-reports/get-call-volume-by-ans";
  static GET_CALL_VOLUME_BY_DATE_LIST = "/v1/report/btrc-reports/get-call-volume-by-date";
  static REPORT_CALL_VOLUME_BY_ROUTE_LIST = "/v1/report/btrc-reports/report-call-volume-by-route";
  static REPORT_CALL_VOLUME_BY_IGW_LIST = "/v1/report/btrc-reports/report-call-volume-by-igw";
  static REPORT_BTRC_CALL_VOLUME_BY_ANS_LIST = "/v1/report/btrc-reports/report-call-volume-by-ans";
  static REPORT_CALL_VOLUME_BY_DATE_LIST = "/v1/report/btrc-reports/report-call-volume-by-date";
  
  static GET_CALL_VOLUME_DETAILS_BY_ANS_LIST = "/v1/report/call-volume-reports/get-call-volume-details-by-ans";
  static GET_CALL_VOLUME_SUMMARY_BY_ANS_LIST = "/v1/report/call-volume-reports/get-call-volume-summary-by-ans";
  static GET_CALL_VOLUME_BY_IOS_LIST = "/v1/report/call-volume-reports/get-call-volume-by-ios";
  static GET_CALL_VOLUME_BY_PARTNER_LIST = "/v1/report/call-volume-reports/get-call-volume-by-partner";
  static GET_FAILED_CDR_REPORT_LIST = "/v1/report/call-volume-reports/get-failed-cdr-list";
  static REPORT_CALL_VOLUME_BY_PARTNER_LIST = "/v1/report/call-volume-reports/report-call-volume-by-partner";
  static REPORT_FAILED_CDR_REPORT_LIST = "/v1/report/call-volume-reports/get-report";
  static REPORT_CALL_VOLUME_DETAILS_BY_ANS_LIST = "/v1/report/call-volume-reports/report-call-volume-details-by-ans";
  static REPORT_CALL_VOLUME_SUMMARY_BY_ANS_LIST = "/v1/report/call-volume-reports/report-call-volume-summary-by-ans";
  static REPORT_CALL_VOLUME_BY_IOS_LIST = "/v1/report/call-volume-reports/report-call-volume-by-ios";

  static GET_CALL_DETAILS_LIST = "/v1/report/call-reports/call-details-list";
  static GET_DAILY_REPORT_LIST = "/v1/report/call-reports/get-daily-report-list";
  static REPORT_DAILY_REPORT_LIST = "/v1/report/call-reports/report-daily-report";
  static GET_POP_WISE_REPORT = "/v1/report/call-reports/get-popwise-report-list";
  static REPORT_POPWISE_REPORT = "/v1/report/call-reports/popwise-report";
  static GET_FULL_CDR_REPORT_LIST = "/v1/report/call-reports/full-cdr-report-list";
  static REPORT_FULL_CDR_REPORT_LIST = "/v1/report/call-reports/full-cdr-report";

  static GET_CCR_REPORT_LIST = "/v1/report/performance/get-ccr-report-list";
  static REPORT_CCR_REPORT_LIST = "/v1/report/performance/ccr-report";

  static LIVE_STATISTIC = "/v1/system/live-statistic";

  static OPERATE_SYSTEM = "/v1/system/operate-system";

  static GET_SYSTEM_STATUS = "/v1/system/get-system-status";

  static PARSER_LOG = "/v1/system/parser-logs";

  static DBA_DATA_FILE_LIST = "/v1/database/get-data-file";

  static GET_DUMMY_API = "/v1/management/dummy/get-list";
}
